import React, { useRef, useContext } from "react"
import { graphql } from "gatsby"

import styled from "styled-components"

import Layout from "../components/layout"
import NewLandingHero from "../components/single-new-landing/hero2"
import NewLandingBenefits from "../components/single-new-landing/benefits"
import NewLandingWorkflow from "../components/single-new-landing/workflow"
import NewLandingFeaturedProject from "../components/single-new-landing/featured-projects"
import NewLandingWhatWeOffer from "../components/single-new-landing/what-we-offer"
import NewLandingGetCracking from "../components/single-new-landing/get-cracking"
import NewLandingClientQuote from "../components/single-new-landing/client-quote"
import InView from "../components/in-view"
import { ButtonContext } from "../components/context/button-context"
import SEO from "../components/single-landing/seo"

const SingleNewLanding = ({ data, pageContext }) => {
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const sectionRef = useRef()

  return (
    <Layout>
      <SEO
        pageTitle={pageContext.title}
        slug={pageContext.slug}
        {...data.wordPress.newLandingBy.seo}
      />
      <NewLandingHero
        hero={data.wordPress.newLandingBy.landingnewpageFields.hero}
        title={data.wordPress.newLandingBy.title}
      />

      <PaddingParralax></PaddingParralax>

      <NewLandingWhatWeOffer
        what={data.wordPress.newLandingBy.landingnewpageFields.whatWeOffer}
      />
      <NewLandingBenefits
        benefits={data.wordPress.newLandingBy.landingnewpageFields.whyChoose}
      />
      <NewLandingWorkflow
        workflow={data.wordPress.newLandingBy.landingnewpageFields.workflow}
      />
      <NewLandingFeaturedProject
        featured={
          data.wordPress.newLandingBy.landingnewpageFields.featuredProjects
        }
      />
      <NewLandingClientQuote
        quote={data.wordPress.newLandingBy.landingnewpageFields.whatClientSay}
      />
      <InView
        element={sectionRef.current}
        setToggle={setButtonTriggers}
        location="cracking"
      >
        <div ref={sectionRef}>
          <NewLandingGetCracking
            start={data.wordPress.newLandingBy.landingnewpageFields.getCracking}
            data={data.wordPress.globalOptions}
          />
        </div>
      </InView>
    </Layout>
  )
}

export default SingleNewLanding

const PaddingParralax = styled.div`
  height: 100vh;
  pointer-events: none;

  @media (max-width: 650px) {
    display: none;
  }
`

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      newLandingBy(newLandingId: $wordpressId) {
        id
        title
        landingnewpageFields {
          ...NewLandingHeroFragment
          ...NewLandingWhatFragment
          ...NewLandingBenefitsFragment
          ...NewLandingWorkflowFragment
          ...NewLandingFeaturedFragment
          ...NewLandingClientQuoteFragment
          ...NewLandingGetCrackingFragment
        }
      }
      globalOptions {
        optionFields {
          contactInfos {
            emailProjects
            phone
            phoneNyc
          }
          startProject {
            ctaText
            formTitle
            introText
            sideText
          }
        }
      }
    }
  }
`
