import React, { useContext, useState, useEffect, useRef } from "react"
import { Link, navigate, graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated, config } from "react-spring"
import Img from "gatsby-image/withIEPolyfill"
import { ButtonContext } from "../context/button-context"
import { ScrollbarContext } from "../context/scrollbar-context"
import InView from "../in-view"
import VerticalMarquee from "../VerticalMarquee"
// import VerticalMarqueeMobile from "../VerticalMarqueeMobile"
import ObserverBg from "../observerBg"

import { TransitionContext } from "../context/transition-context"

export const fragment = graphql`
  fragment NewLandingHeroFragment on WordPress_NewLanding_Landingnewpagefields {
    hero {
      title
      text
      smallTitle
      buttonText
      imageCarousel {
        image {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      imageCarouselMobile {
        image {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1183, height: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

const NewLandingHero = ({ hero }) => {
  const triggerRef = useRef()
  const [scrollbar] = useContext(ScrollbarContext)
  const [isDesktop, setIsDesktop] = useState(null)
  const [heightScreen, setHeightScreen] = useState(0)
  const [heroInfo, setHeroInfo] = useState(true)
  const heroRef = useRef()
  const marqueeMobileRef = useRef()
  const [marqueeInView, setMarqueeInView] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  const onScroll = e => {
    setScrollTop(e.target.documentElement.scrollTop)
    setScrolling(e.target.documentElement.scrollTop > scrollTop)
    springsetScrollY({ springscrollY: e.target.documentElement.scrollTop })
  }

  useEffect(() => {
    if (
      typeof window !== `undefined` &&
      marqueeMobileRef.current
      // marqueeInView
    ) {
      window.addEventListener("scroll", onScroll)
    }

    return function cleanup() {
      window.removeEventListener("scroll", onScroll)
    }
  })

  const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
    springscrollY: 0,
    config: config.slow,
  }))

  springsetScrollY({ springscrollY: scrollTop })
  const interpHeader = springscrollY.interpolate(
    o => `translateX(${-o * 1.1}px)`
  )

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
      setHeightScreen(window.innerHeight)
    }
  }, [])

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (heroRef.current) {
        heroRef.current.style.transform = `translateY(${offset.y}px)`

        if (offset.y > heightScreen * 0.5) {
          setHeroInfo(false)
        } else {
          setHeroInfo(true)
        }
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar && isDesktop) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
    // }, [heightScreen, isDesktop, scrollbar])
  }, [heightScreen, isDesktop, scrollbar])

  const infosAnimation = useSpring({
    // opacity: 1,
    opacity: heroInfo ? 1 : 0,
    from: { opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer ref={isDesktop ? heroRef : null}>
      {!isDesktop && (
        <InView
          element={triggerRef.current}
          setToggle={setButtonTriggers}
          location="hero"
        >
          <TriggerButton ref={triggerRef} />
        </InView>
      )}
      <WrapServiceHero>
        {isDesktop && (
          <WrapSlider
          // style={infosAnimation}
          >
            <VerticalMarquee inView>
              {hero.imageCarousel.map((item, index) => (
                <HeroImg
                  fluid={item.image.imageFile.childImageSharp.fluid}
                  alt={item.image.altText}
                  key={item.image.sourceUrl}
                />
              ))}
            </VerticalMarquee>
          </WrapSlider>
        )}
        <WrapInfos style={infosAnimation}>
          <SmallTitle>{hero.smallTitle}</SmallTitle>
          <Title dangerouslySetInnerHTML={{ __html: hero.title }} />
          <Text dangerouslySetInnerHTML={{ __html: hero.text }} />
          <Link
            to="/contact"
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/contact`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            <ButtonGetInTouch>Get in touch</ButtonGetInTouch>
          </Link>
        </WrapInfos>
      </WrapServiceHero>
      {!isDesktop && (
        <ObserverBg
          element={marqueeMobileRef.current}
          toggle={marqueeInView}
          setToggle={setMarqueeInView}
        >
          <WrapSliderMobile
            ref={marqueeMobileRef}
            style={{ transform: interpHeader }}
          >
            {/* <VerticalMarqueeMobile> */}
            {hero.imageCarouselMobile.map((item, index) => (
              <HeroImg
                // fluid={item.image.imageFile.childImageSharp.fluid}
                fixed={item.image.imageFile.childImageSharp.fixed}
                alt={item.image.altText}
                key={item.image.sourceUrl}
                loading="eager"
              />
            ))}
            {/* </VerticalMarqueeMobile> */}
          </WrapSliderMobile>
        </ObserverBg>
      )}
    </Outer>
  )
}

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  padding: 0 7.5%;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  z-index: 0;

  @media (max-width: 1260px) {
    padding: 0 10% 17rem 10%;
  }

  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    padding: 25rem 0 0 0;
    overflow: hidden !important;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    z-index: -1;
  }

  @media (max-width: 650px) {
    position: relative;
    min-height: 100%;
    height: 100%;
    padding: 15rem 0 0 0;
    max-width: 100vw;
  }

  @media (max-width: 650px) and (min-height: 750px) {
    padding: 25rem 0 0 0;
  }
`

const TriggerButton = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  height: 1px;
  background-color: transparent;
`

const WrapServiceHero = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    justify-content: flex-start;
  }
`

const WrapInfos = styled(animated.div)`
  position: relative;
  /* width: 41%; */
  width: 43%;
  margin: -2.5% 0 0 7%;
  /* z-index: -5; */

  @media (min-width: 1600px) {
    width: 34%;
  }

  @media (max-width: 1300px) {
    width: 45%;
    margin: -2.5% 0 0 5%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0 7.5% 10% 7.5%;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin: 0 25px 10% 25px;
  }

  & img {
    width: 100%;

    @media (max-width: 650px) {
      width: 60%;
    }
  }
`

const Title = styled.div`
  display: block;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 6rem;
  font-weight: 700;
  color: ${props => props.theme.colours.grey};
  margin: 0;
  padding-bottom: 2rem;

  @media (max-width: 1300px) {
    font-size: 5.1rem;
  }

  @media (max-width: 1100px) {
    font-size: 4.5rem;
  }

  @media (max-width: 1024px) {
    font-size: 5rem;
  }

  @media (max-width: 650px) {
    font-size: 6.5rem;
  }

  h1 {
    display: block;
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 6rem;
    font-weight: 700;
    color: ${props => props.theme.colours.grey};
    margin: 0;
    padding-bottom: 2rem;

    @media (max-width: 1300px) {
      font-size: 5.1rem;
    }

    @media (max-width: 1100px) {
      font-size: 4.5rem;
    }

    @media (max-width: 1024px) {
      font-size: 5rem;
    }

    @media (max-width: 650px) {
      font-size: 6.5rem;
    }
  }
`

const SmallTitle = styled.p`
  font-size: 1.2rem;
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => props.theme.colours.pink};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`

const Text = styled.div`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.6rem;
  font-weight: 300;

  @media (max-width: 1024px) {
    font-size: 2.2rem;
  }

  @media (max-width: 650px) {
    font-size: 2.6rem;
  }

  strong {
    color: ${props => props.theme.colours.pink};
    font-weight: 300;
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-size: 1.6rem;
    font-weight: 300;

    @media (max-width: 1024px) {
      font-size: 2.2rem;
    }

    @media (max-width: 650px) {
      font-size: 2.6rem;
    }

    strong {
      color: ${props => props.theme.colours.pink};
      font-weight: 300;
    }
  }
`

const ButtonGetInTouch = styled.button`
  /* background-color: ${props => props.theme.colours.pink}; */
  background-color: transparent;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  letter-spacing: 0.1rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50px;
  padding: 20px 50px;
  font-size: 12px;
  pointer-events: inherit;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  border: 0.25s ease-in-out;
  z-index: 5;

  @media (max-width: 650px) {
    right: 2.5rem;
    width: 25rem;
    font-size: 10px;
    padding: 15px 25px;
    /* border: 0; */
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
    /* border: 2px solid ${props => props.theme.colours.white}; */

    @media (max-width: 650px) {
      border: 0;
    }
  }
`

const WrapSlider = styled(animated.div)`
  position: relative;
  width: 50%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  display: block;
  pointer-events: none;

  @media (max-width: 1024px) {
    width: calc(100% + 14rem);
    transform: translateX(-7rem);
    height: auto;
    display: none;
  }
`

const WrapSliderMobile = styled(animated.div)`
  display: none;

  @media (max-width: 1024px) {
    width: auto;
    min-width: 435%;
    height: auto;
    display: flex;
  }

  @media (max-width: 767px) {
    /* @media (max-width: 1024px) { */
    width: auto;
    min-width: 735%;
    /* min-width: 2890px; */
    height: auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    /* will-change: transform; */
  }

  @media (max-width: 429px) {
    width: auto;
    min-width: 672%;
    /* min-width: 1428px; */
    height: auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  @media (max-width: 420px) {
    width: auto;
    min-width: 695%;
    /* min-width: 1428px; */
    height: auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  @media (max-width: 380px) {
    width: auto;
    min-width: 772%;
    /* min-width: 1428px; */
    height: auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    /* will-change: transform; */
  }
`

const HeroImg = styled(Img)`
  width: 85%;
  margin: 0 auto 10% auto;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35);

  @media (max-width: 1024px) {
    position: unset !important;
    width: 100%;
    height: 90px !important;
    border-radius: 0;
    box-shadow: none;
  }

  @media (max-width: 650px) {
    width: 100% !important;
    height: 180px !important;
    border-radius: 0;
    margin: 0 0 35px 25px;
    box-shadow: none;

    img {
      width: auto !important;
      /* width: 100% !important; */
      height: 100% !important;
    }
  }
`

export default NewLandingHero
