import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"
import SwiperEl from "react-id-swiper"
import { Swiper, Navigation } from "swiper/js/swiper.esm"
import "swiper/css/swiper.css"
import { aspectRatio } from "../../helpers/aspect-ratio"
import Observer from "../observer"

import ArrowTeamNext from "../../images/next-arrow-pink.svg"
import ArrowTeamPrev from "../../images/prev-arrow-pink.svg"
import Quote from "../../images/quote.svg"

export const fragment = graphql`
  fragment NewLandingClientQuoteFragment on WordPress_NewLanding_Landingnewpagefields {
    whatClientSay {
      title
      saysList {
        ceoName
        clientName
        quote
        image {
          id
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const NewLandingClientQuote = ({ quote }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [swiper, updateSwiper] = useState(null)
  const ref = useRef()
  const contentRef = useRef()
  const swiperRef = useRef(null)

  const params = {
    Swiper,
    effect: "fade",
    preloadImages: true,
    speed: 500,
    loop: true,
    fadeEffect: {
      crossFade: true,
    },
  }

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
    swiper.slideNext()
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
    swiper.slidePrev()
  }

  useEffect(() => {
    console.log("swiperRef.current", swiperRef.current)
    console.log("swiper", swiper)
  }, [swiper])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <WrapWhat
          ref={ref}
          // style={titleAnimation}
        >
          <WrapTopLeft style={titleAnimation}>
            <WrapText
              dangerouslySetInnerHTML={{ __html: quote.title }}
            ></WrapText>
          </WrapTopLeft>
          <WrapTopRight style={titleAnimation}>
            <PrevArrow className="swiper-button-prev" onClick={goPrev}>
              <ArrowTeamPrev />
            </PrevArrow>
            <NextArrow className="swiper-button-next" onClick={goNext}>
              <ArrowTeamNext />
            </NextArrow>
          </WrapTopRight>
          <WrapItems style={titleAnimation}>
            <SwiperOuter>
              <SwiperEl getSwiper={updateSwiper} {...params} ref={swiperRef}>
                {quote.saysList.map((item, index) => (
                  <Item key={item.title}>
                    <ItemWrapLeft>
                      <img src={item.image.sourceUrl} alt="" />
                    </ItemWrapLeft>
                    <ItemWrapRight>
                      <WrapIcon>
                        <Quote />
                      </WrapIcon>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.quote }}
                      ></div>
                      <CeoName>{item.ceoName}</CeoName>
                      <ClientName>{item.clientName}</ClientName>
                    </ItemWrapRight>
                  </Item>
                ))}
              </SwiperEl>
            </SwiperOuter>
            <WrapBottomMobile>
              <PrevArrow className="swiper-button-prev" onClick={goPrev}>
                <ArrowTeamPrev />
              </PrevArrow>
              <NextArrow className="swiper-button-next" onClick={goNext}>
                <ArrowTeamNext />
              </NextArrow>
            </WrapBottomMobile>
          </WrapItems>
        </WrapWhat>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 100%;
  z-index: 2;
`

const WrapWhat = styled(animated.div)`
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  padding: 10rem 12.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => props.theme.colours.white};
  z-index: 2;

  @media (max-width: 1100px) {
    /* flex-direction: column; */
    /* position: aboslute; */
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 10rem 7.5%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    /* flex-direction: column; */
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 10rem 7.5%;
    height: 100%;
  }

  @media (max-width: 650px) {
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 0 3.5rem 65px 3.5rem;
    height: 100%;
  }
`

const WrapTopLeft = styled(animated.div)`
  width: 50%;

  @media (max-width: 650px) {
    width: 100%;
  }
`

const WrapTopRight = styled(animated.div)`
  position: relative;
  width: 50%;

  @media (max-width: 650px) {
    width: 100%;
    display: none;
  }
`

const WrapText = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.grey};
  font-size: 8rem;
  line-height: 1;
  max-width: 12ch;

  @media (max-width: 650px) {
    max-width: 100%;
    font-size: 7rem;
    line-height: 1.1;
    margin-bottom: 5rem;

    br {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    width: 80%;
    max-width: 15ch;
  }

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
    /* max-width: 100%; */
    max-width: 10ch;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    font-size: 8rem;
    line-height: 1;
    max-width: 7ch;

    @media (max-width: 1024px) {
      max-width: 100%;
      font-size: 7rem;
      line-height: 1.1;
      margin-bottom: 5rem;

      br {
        display: none;
      }
    }
  }
`

const WrapItems = styled(animated.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 6.5rem;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 5rem;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-top: 0;
  }
`

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: ${props => props.theme.colours.white};

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-top: 2rem;
  }

  h3 {
    color: ${props => props.theme.colours.grey};
    font-size: 5.5rem;
    word-break: keep-all;
    overflow-wrap: normal;

    @media (max-width: 1300px) {
      font-size: 4rem;
    }

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }
`

const SwiperOuter = styled.div`
  position: relative;
  max-width: 100vw;
  overflow: hidden;
`

const WrapBottomMobile = styled.div`
  display: none;

  @media (max-width: 650px) {
    position: relative;
    width: 80%;
    height: 75px;
    margin-left: 20%;
  }
`

const ItemWrapLeft = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    display: none;
  }

  img {
    max-width: 75%;
    height: auto;
  }
`

const ItemWrapRight = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
    padding-left: 20%;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    font-size: 3.5rem;
    line-height: 1.33;
    max-width: 25ch;

    @media (min-width: 1600px) {
      max-width: 40ch;
    }

    @media (max-width: 1024px) {
      max-width: 40ch;
    }

    @media (max-width: 650px) {
      font-size: 3.3rem;
      max-width: 100%;
    }
  }
`
const CeoName = styled.p`
  font-size: 1.6rem !important;
  font-family: ${props => props.theme.fonts.circular} !important;
  max-width: 100% !important;
  margin-top: 4rem;
`
const ClientName = styled.p`
  font-size: 1.6rem !important;
  font-family: ${props => props.theme.fonts.circular} !important;
  max-width: 100% !important;
`

const WrapIcon = styled.div`
  position: absolute;
  top: 10px;
  left: -70px;

  @media (max-width: 1024px) {
    left: 25px;
  }

  @media (max-width: 650px) {
    left: 0;
  }
`

const PrevArrow = styled.div`
  /* position: relative !important; */
  left: 0 !important;
  top: unset !important;
  bottom: 0 !important;
  height: 75px !important;
  width: 75px !important;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50%;
  pointer-events: all;

  &:after {
    display: none;
  }

  @media (max-width: 1024px) {
    height: 65px !important;
    width: 65px !important;
    bottom: 20% !important;
    right: 52% !important;
  }

  @media (max-width: 650px) {
    height: 55px !important;
    width: 55px !important;
    bottom: 20% !important;
    left: 0% !important;
    /* right: 54% !important; */

    svg {
      width: 50% !important;
      height: 50% !important;

      line {
        stroke: ${props => props.theme.colours.pink} !important;
        fill: ${props => props.theme.colours.pink} !important;
      }
    }
  }

  @media (max-width: 400px) {
    bottom: 10% !important;
  }
`

const NextArrow = styled.div`
  /* position: relative !important; */
  bottom: 0 !important;
  top: unset !important;
  left: 100px !important;
  /* margin-left: 100px; */
  height: 75px !important;
  width: 75px !important;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50%;
  pointer-events: all;

  &:after {
    display: none;
  }

  @media (max-width: 1024px) {
    bottom: 20% !important;
    height: 65px !important;
    width: 65px !important;
    right: 40% !important;
  }

  @media (max-width: 650px) {
    bottom: 20% !important;
    height: 55px !important;
    width: 55px !important;
    left: 25% !important;
    right: unset !important;

    svg {
      width: 50% !important;
      height: 50% !important;
    }
  }

  @media (max-width: 400px) {
    bottom: 10% !important;
  }
`

export default NewLandingClientQuote
