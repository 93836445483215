import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"
import { aspectRatio } from "../../helpers/aspect-ratio"
import Observer from "../observer"

export const fragment = graphql`
  fragment NewLandingWhatFragment on WordPress_NewLanding_Landingnewpagefields {
    whatWeOffer {
      title
      text
      offerList {
        title
        icon {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const NewLandingWhatWeOffer = ({ what }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const ref = useRef()

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      {/* <PaddingParralax></PaddingParralax> */}
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <WrapWhat
          ref={ref}
          // style={titleAnimation}
        >
          <WrapLeft>
            <WrapText
              dangerouslySetInnerHTML={{ __html: what.title }}
            ></WrapText>
            <WrapContent
              dangerouslySetInnerHTML={{ __html: what.text }}
            ></WrapContent>
          </WrapLeft>
          <WrapRight>
            <WrapItems>
              {what.offerList.map((item, index) => (
                <Item key={item.title}>
                  {/* <Img fluid={item.icon.imageFile.childImageSharp.fluid} /> */}
                  <WrapIcon>
                    <img src={item.icon.sourceUrl} alt="" />
                  </WrapIcon>
                  <h3>{item.title}</h3>
                </Item>
              ))}
            </WrapItems>
          </WrapRight>
        </WrapWhat>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 100%;
  z-index: 2;
`

const WrapWhat = styled(animated.div)`
  padding: 10rem 12.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.colours.white};
  z-index: 2;

  @media (max-width: 1100px) {
    flex-direction: column;
    /* position: aboslute; */
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 10rem 7.5%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 10rem 7.5%;
    height: 100%;
  }

  @media (max-width: 650px) {
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 65px 3.5rem;
    height: 100%;
  }
`

const WrapLeft = styled.div`
  width: 40%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const WrapRight = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const WrapText = styled.div`
  /* width: 40%; */
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.pink};
  font-size: 8rem;
  line-height: 1;
  max-width: 7ch;

  @media (max-width: 650px) {
    max-width: 100%;
    font-size: 7rem;
    line-height: 1.1;
    margin-bottom: 5rem;

    br {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    width: 80%;
    max-width: 15ch;
  }

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey} !important;
    font-size: 8rem;
    line-height: 1;
    max-width: 7ch;
    margin-top: 2.5rem;

    @media (max-width: 1024px) {
      max-width: 100%;
      font-size: 7rem;
      line-height: 1.1;
      margin-bottom: 5rem;

      br {
        display: none;
      }
    }
  }
`

const WrapContent = styled.div`
  p {
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.grey} !important;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: 2.5rem;

    @media (max-width: 1024px) {
      max-width: 100%;
      font-size: 2.2rem;
      line-height: 1.5;
      margin-bottom: 5rem;

      br {
        display: none;
      }
    }
  }
`

const WrapItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: space-between;
  /* width: 60%; */

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const Item = styled.div`
  width: 45%;
  margin-bottom: 6rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-last-child(2) {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 650px) {
    width: 50%;
    margin-top: 2rem;
  }

  h3 {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    font-size: 2.2rem;
    max-width: 14ch;
    margin: 0 auto;
    /* word-break: keep-all; */
    /* overflow-wrap: normal; */
    text-align: center;
    margin-top: 3rem;

    @media (max-width: 1300px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      font-size: 3rem;
    }
  }
`

const WrapIcon = styled.div`
  position: relative;
  width: 132px;
  height: 132px;
  margin: 0 auto;
  /* padding-bottom: 100%; */
  background: rgb(75, 163, 252);
  background: linear-gradient(
    29deg,
    rgba(75, 163, 252, 1) 0%,
    rgba(238, 83, 242, 1) 100%
  );
  border-radius: 10px;
  box-shadow: 0 33px 34px rgba(0, 0, 0, 0.15);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55px;
    height: 55px;
  }
`

export default NewLandingWhatWeOffer
