import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"
import ReactPlayer from "react-player"
import Observer from "../observer"

export const fragment = graphql`
  fragment NewLandingBenefitsFragment on WordPress_NewLanding_Landingnewpagefields {
    whyChoose {
      title
      whyList {
        text
        title
        image {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        video {
          mediaItemUrl
        }
      }
    }
  }
`

const NewLandingBenefits = ({ benefits }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedImage, setAnimatedImage] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const ref = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const imageAnimation = useSpring({
    opacity: animatedImage ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <Observer
          element={ref.current}
          toggle={animatedImage}
          setToggle={setAnimatedImage}
        >
          <WrapBenefits ref={ref}>
            {benefits.whyList.map((item, index) => (
              <Benefit style={titleAnimation} key={item.title}>
                <WrapText>
                  <h3>{item.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.text }} />
                </WrapText>
                <WrapImg style={imageAnimation}>
                  {!item.video && (
                    <Img fluid={item.image.imageFile.childImageSharp.fluid} />
                  )}
                  {item.video && (
                    <ReactPlayer
                      url={item.video.mediaItemUrl}
                      width="100%"
                      height="100%"
                      playing
                      controls={false}
                      muted
                      loop
                      playsinline
                    />
                  )}
                </WrapImg>
              </Benefit>
            ))}
          </WrapBenefits>
        </Observer>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 14.5rem 12.5%;
  display: flex;
  flex-wrap: wrap;
  background-color: ${props => props.theme.colours.grey};
  z-index: 5;

  @media (max-width: 1024px) {
    padding: 14.5rem 7.5% 14.5rem 7.5%;
  }

  @media (max-width: 650px) {
    padding: 12rem 3.5rem 65px 3.5rem;
  }
`

const WrapBenefits = styled.div`
  width: 100%;
`

const Benefit = styled(animated.div)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 15rem;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @media (max-width: 650px) {
    margin-top: 10rem;
  }
`

const WrapText = styled.div`
  width: 40%;

  @media (max-width: 650px) {
    width: 100%;
  }

  h3 {
    font-size: 5.5rem;

    @media (max-width: 650px) {
      font-size: 5rem;
      margin-bottom: 3rem;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.8;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.white};

    @media (max-width: 650px) {
      font-size: 2.3rem;
      margin-bottom: 5rem;
    }
  }
`

const WrapImg = styled(animated.div)`
  width: 50%;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`

export default NewLandingBenefits
