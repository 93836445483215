import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import Img from "gatsby-image/withIEPolyfill"
import usePortal from "react-useportal"
import Observer from "../observer"
import ObserverBg from "../observerBg"

import CrossSvg from "../../images/cross.svg"
import ContactForm from "../contact/form"
import CopyToClipboard from "../../helpers/copy-clipboard"

export const fragment = graphql`
  fragment NewLandingGetCrackingFragment on WordPress_NewLanding_Landingnewpagefields {
    getCracking {
      text
      title
      buttonText
      bgImage {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      bgImageMobile {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const NewLandingGetCracking = ({ start, data }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [isDesktop, setIsDesktop] = useState(null)
  const [marqueeInView, setMarqueeInView] = useState(false)
  const sectionRef = useRef()
  const ref = useRef()

  const startProjectContact = data.optionFields.contactInfos

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    onOpen({ portal }) {
      portal.current.style.cssText = `
        position: absolute;
        height: 100vh;
        overflow: visible;
        z-index: 1000;
      `
    },
  })

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer ref={sectionRef}>
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <ObserverBg
          element={ref.current}
          toggle={marqueeInView}
          setToggle={setMarqueeInView}
        >
          <WrapIntro ref={ref}>
            <WrapText style={titleAnimation}>
              <h2>{start.title}</h2>
              <Text dangerouslySetInnerHTML={{ __html: start.text }} />
              <StartButtonWrap>
                <StartButton onClick={openPortal}>
                  {start.buttonText}
                </StartButton>
              </StartButtonWrap>
            </WrapText>
          </WrapIntro>
          <WrapBgImage>
            {isDesktop && (
              <Img fluid={start.bgImage.imageFile.childImageSharp.fluid} />
            )}
            {!isDesktop && (
              <Img
                fluid={start.bgImageMobile.imageFile.childImageSharp.fluid}
              />
            )}
          </WrapBgImage>
        </ObserverBg>
      </Observer>
      <BgColor />

      <Portal>
        <Modal className={isOpen ? "active" : ""}>
          <ModalContents>
            <Close onClick={closePortal}>
              <CrossSvg />
            </Close>

            <WrapContent>
              <WrapLeft>
                <Title>{data.optionFields.startProject.introText}</Title>
                <p>Contact us</p>
                <WrapEmail>
                  <CopyToClipboard text={startProjectContact.emailProjects} />
                </WrapEmail>
                <WrapPhone>
                  <span>London: </span>
                  <a href={`tel:${startProjectContact.phone}`}>
                    {startProjectContact.phone}
                  </a>
                </WrapPhone>
                <WrapPhone>
                  <span>New York: </span>
                  <a href={`tel:${startProjectContact.phoneNyc}`}>
                    {startProjectContact.phoneNyc}
                  </a>
                </WrapPhone>
              </WrapLeft>

              <WrapRight>
                <InsideWrap>
                  <h2>{startProjectContact.formTitle}</h2>
                  <ContactForm />
                </InsideWrap>
              </WrapRight>
            </WrapContent>
          </ModalContents>
          <ModalBackground />
        </Modal>
      </Portal>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 7.5%;
  display: flex;
  flex-wrap: wrap;
  pointer-events: all;
  max-height: 85vh;
  min-height: 85vh;
  background-color: ${props => props.theme.colours.pink};

  @media (max-width: 1024px) {
    padding: 0 0 10rem 0;
    max-height: 100%;
    min-height: 100%;
  }

  @media (max-width: 650px) {
    /* padding: 3.5rem 3.5rem 65px 3.5rem; */
    padding: 0 0 65px 0;
    overflow: hidden;
    z-index: 10;
    max-height: 100%;
    min-height: 100%;
  }

  @media (min-width: 1900px) {
    padding: 15% 7.5%;
  }
`

const BgColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colours.pink};
  z-index: -2;

  @media (max-width: 1024px) {
    position: relative;
    z-index: 2;
  }
`

const WrapIntro = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  z-index: 2;

  @media (max-width: 1024px) {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 0;
    margin-top: 0;
    z-index: 3;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    display: block;
    padding: 0 3.5rem 0 3.5rem;
  }
`

const WrapBgImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  > div {
    position: unset !important;
  }

  img {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    position: relative;
    margin-bottom: 10rem;
    /* display: none; */

    img {
      position: relative !important;
      width: 1000px !important;
      height: 200px !important;
    }
  }

  @media (max-width: 650px) {
    position: relative;
    margin-bottom: 0;
    /* display: none; */

    img {
      position: relative !important;
      width: 1000px !important;
      height: 200px !important;
    }
  }
`

const WrapText = styled(animated.div)`
  width: 42%;
  /* margin-left: 53%; */

  @media (min-width: 1600px) {
    width: 34%;
    /* margin-left: 57%; */
  }

  @media (max-width: 1024px) {
    /* margin-top: 5rem; */
    width: 100%;
    margin-left: 0;
    padding: 0 7.5%;
  }

  @media (max-width: 650px) {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  h2 {
    font-size: 5.5rem;
    margin-bottom: 3rem;

    @media (max-width: 650px) {
      font-size: 7.2rem;
      margin-bottom: 4rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.4;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.white};

    @media (max-width: 650px) {
      font-size: 2.5rem;
    }
  }
`

const Text = styled(animated.div)`
  font-size: 1.6rem;
  line-height: 1.4;
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => props.theme.colours.white};

  @media (max-width: 1024px) {
    max-width: 50%;
  }

  @media (max-width: 650px) {
    max-width: 100%;
    font-size: 2.6rem;
    line-height: 1.5;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.4;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.white};

    @media (max-width: 650px) {
      font-size: 2.6rem;
    }
  }
`

const StartButtonWrap = styled(animated.figure)`
  margin-top: 4rem;
`

const StartButton = styled(animated.button)`
  background-color: transparent;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  letter-spacing: 0.1rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid ${props => props.theme.colours.white};
  border-radius: 50px;
  padding: 20px 30px;
  font-size: 12px;
  pointer-events: all;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
    border 0.25s ease-in-out;
  z-index: 5;

  @media (max-width: 650px) {
    /* width: 25rem; */
    width: auto;
    font-size: 12px;
    padding: 23px 34px;
  }

  &:hover {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
    border: 2px solid ${props => props.theme.colours.pink};

    @media (max-width: 650px) {
      border: 0;
    }
  }
`

const ModalContents = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  transition: transform 0.3s ease;
  overflow: hidden;
  z-index: 5;
`

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
`

const Modal = styled(animated.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 100;
  overflow: hidden;

  @media (max-width: 650px) {
    height: 100vh;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 35px;
    height: 35px;
  }
`

const WrapLeft = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 15rem 15% 2.5rem 15%;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin: 15rem 0 7.5rem 0;
  }

  p {
    font-size: 2rem;
    color: ${props => props.theme.colours.lightGrey};
    padding-top: 5rem;

    @media (max-width: 1024px) {
      padding-top: 2.5rem;
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      padding-top: 2.5rem;
      font-size: 2.85rem;
    }
  }

  a {
    display: block;
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.pink};
    font-size: 3.4rem;

    @media (max-width: 1024px) {
      font-size: 5rem;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem;
    }
  }
`

const Title = styled.h2`
  width: 80%;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 4.5rem;
  color: ${props => props.theme.colours.pink};

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 4.5rem;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`

const WrapPhone = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1.5;

  @media (max-width: 1224px) {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  a {
    font-size: 2.6rem !important;

    @media (min-width: 1920px) {
      font-size: 3.4rem !important;
    }

    @media (max-width: 1024px) {
      font-size: 2.8rem !important;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem !important;
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 2.5rem;
  }

  span {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    font-size: 2.6rem;

    @media (min-width: 1920px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1024px) {
      font-size: 2.8rem;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem;
    }
  }
`

const WrapEmail = styled.div`
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.pink};
  font-size: 3.4rem;
  margin-top: 3rem;

  @media (max-width: 1024px) {
    font-size: 5rem;
  }

  @media (max-width: 650px) {
    font-size: 3.8rem;
    margin-top: 4rem;
  }

  div {
    color: ${props => props.theme.colours.pink} !important;

    @media (max-width: 650px) {
      font-size: 3.8rem !important;
      margin-bottom: 2rem !important;
    }
  }
`

const InsideWrap = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 10rem;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`

const WrapRight = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 1024px) {
    padding: 5rem 15% 15rem 15%;
    width: 100%;
  }

  @media (max-width: 650px) {
    padding: 7.5rem 0 15rem 0;
    width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.colours.grey};
    margin-right: 100px;
    display: block;

    @media (max-width: 1024px) {
      width: 100%;
      height: 1px;
      display: none;
    }

    @media (max-width: 650px) {
      width: 100%;
      height: 1px;
      display: block;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 45px;

    @media (max-width: 1024px) {
      font-size: 5.5rem;
    }

    @media (max-width: 650px) {
      font-size: 30px;
    }
  }
`

const WrapContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  height: 85%;
  overflow-y: scroll;
  margin: 0 auto;
  padding: 3rem 0;

  @media (max-width: 1660px) and (max-height: 740px) {
    width: 85%;
    height: 95%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 3rem;
  }

  @media (max-width: 1320px) and (max-height: 675px) {
    width: 85%;
    height: 90%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 0 3rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    margin: 0 auto;
    padding: 3rem;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
`

export default NewLandingGetCracking
